<div id="left-side-bar" class="menu-drawer menu-drawer-open">
  <div class="nav-logo">
    <a routerLink="/home">
      <img src="/assets/icons/logo.svg" alt="old mutual logo" />
    </a>
    <div class="user-info">
      <div class="user-photo">
        <img src="/assets/picture/user-photo.png" alt="user photo" />
      </div>
      <div class="user-name">
        <p class="">Hello, <span>Sainath Patil</span></p>
        <div class="profile-dropdown">
          <ul>
            <li><img src="/assets/icons/my-profile-icon.png" alt="profile" />My Profile</li>
            <li><img src="/assets/icons/settings-icon.png" alt="profile" />Settings</li>
            <li (click)="logout()"><img src="/assets/icons/profile-logout-icon.png" alt="profile" />Logout</li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <button mat-flat-button class="btn expand-menu-button" (click)="menuOpen()">
    <img src="/assets/icons/expand-side-menu-icon.svg" alt="expand menu icon" />
  </button>

  <nav>
    <ul>
      <li *ngIf="getPermission('Dashboard' , '', '')">
        <a mat-button href="#" class="menu-link">
          <span class="nav-icon">
            <img src="assets/icons/dashboard-menu-icon.svg" alt="dashboard">
          </span>
          <mat-label>Dashboard</mat-label>
        </a>
      </li>
      <li class="submenu-level" [ngClass]="{'active-menu': isMenuHeaderActive('d365')}"
        *ngIf="getPermission('D365Masters' , '', '')">
        <a mat-button class="menu-link">
          <span class="nav-icon">
            <img src="assets/icons/d365.svg" alt="D365 Master">
          </span>
          <mat-label>D365 Masters</mat-label>
        </a>
        <div class="submenu submenu-border">
          <ul>
            <li class="has-submenu" [ngClass]="{'active': isSubMenuHeaderActive('chartOfAccount')}"
              *ngIf="getPermission('D365Masters' , 'ChartofAccounts', '')">
              <a mat-button href="javascript:void(0)">Chart of Accounts</a>
              <div class="submenu-level-2">
                <a mat-button href="#" [ngClass]="{'active': routerUrl.url=='/main-account'}"
                  *ngIf="getPermission('D365Masters' , 'ChartofAccounts', 'MainAccount')"
                  routerLink="/main-account">Main Account</a>
                <a mat-button href="#" [ngClass]="{'active': routerUrl.url=='/main-account-category'}"
                  routerLink="/main-account-category"
                  *ngIf="getPermission('D365Masters' , 'ChartofAccounts', 'MainAccountCategories')">Main Account
                  Categories</a>
              </div>
            </li>
            <li class="has-submenu" [ngClass]="{'active': isSubMenuHeaderActive('dimension')}"
              *ngIf="getPermission('D365Masters' , 'Dimensions', '')">
              <div class="sidemenu-dropdown"></div>
              <a mat-button href="javascript:void(0)">Dimensions</a>
              <div class="submenu-level-2">
                <a mat-button href="#" [ngClass]="{'active': routerUrl.url=='/dimension/country-cluster'}"
                  routerLink="/dimension/country-cluster"
                  *ngIf="getPermission('D365Masters' , 'Dimensions', 'CountryClusters')">Country Clusters</a>
                <a mat-button href="#" [ngClass]="{'active': routerUrl.url=='/dimension/country'}"
                  routerLink="/dimension/country"
                  *ngIf="getPermission('D365Masters' , 'Dimensions', 'Country')">Country</a>
                <a mat-button href="#" [ngClass]="{'active': routerUrl.url=='/dimension/legal-entity'}"
                  routerLink="/dimension/legal-entity"
                  *ngIf="getPermission('D365Masters' , 'Dimensions', 'LegalEntity')">Legal Entity</a>
                <a mat-button href="#" [ngClass]="{'active': routerUrl.url=='/dimension/line-of-business'}"
                  routerLink="/dimension/line-of-business"
                  *ngIf="getPermission('D365Masters' , 'Dimensions', 'LineofBusiness')">Line of Business</a>
                <a mat-button href="#" [ngClass]="{'active': routerUrl.url=='/dimension/segment'}"
                  routerLink="/dimension/segment"
                  *ngIf="getPermission('D365Masters' , 'Dimensions', 'Segment')">Segment</a>
                <a mat-button href="#" [ngClass]="{'active': routerUrl.url=='/dimension/channel'}"
                  routerLink="/dimension/channel"
                  *ngIf="getPermission('D365Masters' , 'Dimensions', 'Channel')">Channel</a>
                <a mat-button href="#" [ngClass]="{'active': routerUrl.url=='/dimension/region'}"
                  routerLink="/dimension/region"
                  *ngIf="getPermission('D365Masters' , 'Dimensions', 'Region')">Region</a>
                <a mat-button href="#" [ngClass]="{'active': routerUrl.url=='/dimension/area'}"
                  routerLink="/dimension/area" *ngIf="getPermission('D365Masters' , 'Dimensions', 'Area')">Area</a>
                <a mat-button href="#" [ngClass]="{'active': routerUrl.url=='/dimension/branch'}"
                  routerLink="/dimension/branch"
                  *ngIf="getPermission('D365Masters' , 'Dimensions', 'Branch')">Branch</a>
                <a mat-button href="#" [ngClass]="{'active': routerUrl.url=='/dimension/portfolio'}"
                  routerLink="/dimension/portfolio"
                  *ngIf="getPermission('D365Masters' , 'Dimensions', 'Portfolio')">Portfolio</a>
                <a mat-button href="#" [ngClass]="{'active': routerUrl.url=='/dimension/product'}"
                  routerLink="/dimension/product"
                  *ngIf="getPermission('D365Masters' , 'Dimensions', 'Product')">Product</a>
                <a mat-button href="#" [ngClass]="{'active': routerUrl.url=='/dimension/department'}"
                  routerLink="/dimension/department"
                  *ngIf="getPermission('D365Masters' , 'Dimensions', 'Department')">Department</a>
                <a mat-button href="#" [ngClass]="{'active': routerUrl.url=='/dimension/project'}"
                  routerLink="/dimension/project"
                  *ngIf="getPermission('D365Masters' , 'Dimensions', 'Project')">Project</a>
                <a mat-button href="#" [ngClass]="{'active': routerUrl.url=='/dimension/intercompany'}"
                  routerLink="/dimension/intercompany"
                  *ngIf="getPermission('D365Masters' , 'Dimensions', 'Intercompany')">Inter-company</a>
                <a mat-button href="#" [ngClass]="{'active': routerUrl.url=='/dimension/pns'}"
                  routerLink="/dimension/pns" *ngIf="getPermission('D365Masters' , 'Dimensions', 'Pns')">Pns</a>
                <a mat-button href="#" [ngClass]="{'active': routerUrl.url=='/dimension/transactiontype'}"
                  routerLink="/dimension/transactiontype"
                  *ngIf="getPermission('D365Masters' , 'Dimensions', 'TransactionType')">Transaction Type</a>
              </div>
            </li>
          </ul>
        </div>
      </li>
      <li class="submenu-level" [ngClass]="{'active-menu': isMenuHeaderActive('mapping')}"
        *ngIf="getPermission('Mappings' , '', '')">
        <a mat-button>
          <span class="nav-icon">
            <img src="assets/icons/mapping.svg" alt="Mapping">
          </span>
          <mat-label>Mappings</mat-label>
        </a>
        <div class="submenu submenu-border">
          <ul>
            <li class="has-submenu submenu-arrow" [ngClass]="{'active': routerUrl.url=='/mapping/main-account'}"
              *ngIf="getPermission('Mappings' , 'MainAccount', '')">
              <a mat-button href="javascript:void(0)" routerLink="/mapping/main-account">Main Account</a>
            </li>
            <li class="has-submenu" [ngClass]="{'active': isSubMenuHeaderActive('dimension-mapping')}"
              *ngIf="getPermission('Mappings' , 'Dimensions', '')">
              <div class="sidemenu-dropdown"></div>
              <a mat-button href="javascript:void(0)">Dimensions</a>
              <div class="submenu-level-2">
                <a mat-button href="#" [ngClass]="{'active': routerUrl.url=='/mapping/lineofbusiness'}"
                  routerLink="/mapping/lineofbusiness"
                  *ngIf="getPermission('Mappings' , 'Dimensions', 'LineofBusiness')">Line of Business</a>
                <a mat-button href="#" [ngClass]="{'active': routerUrl.url=='/mapping/segment'}"
                  routerLink="/mapping/segment" *ngIf="getPermission('Mappings' , 'Dimensions', 'Segment')">Segment</a>
                <a mat-button href="#" [ngClass]="{'active': routerUrl.url=='/mapping/channel'}"
                  routerLink="/mapping/channel" *ngIf="getPermission('Mappings' , 'Dimensions', 'Channel')">Channel</a>
                <a mat-button href="#" [ngClass]="{'active': routerUrl.url=='/mapping/branch'}"
                  routerLink="/mapping/branch" *ngIf="getPermission('Mappings' , 'Dimensions', 'Branch')">Branch</a>
                <a mat-button href="#" [ngClass]="{'active': routerUrl.url=='/mapping/product'}"
                  routerLink="/mapping/product" *ngIf="getPermission('Mappings' , 'Dimensions', 'Product')">Product</a>
                <a mat-button href="#" [ngClass]="{'active': routerUrl.url=='/mapping/department'}"
                  routerLink="/mapping/department"
                  *ngIf="getPermission('Mappings' , 'Dimensions', 'Department')">Department</a>
                <a mat-button href="#" [ngClass]="{'active': routerUrl.url=='/mapping/project'}"
                  routerLink="/mapping/project" *ngIf="getPermission('Mappings' , 'Dimensions', 'Project')">Project</a>
                <a mat-button href="#" [ngClass]="{'active': routerUrl.url=='/mapping/intercompany'}"
                  routerLink="/mapping/intercompany"
                  *ngIf="getPermission('Mappings' , 'Dimensions', 'Intercompany')">Intercompany</a>
                <a mat-button href="#" [ngClass]="{'active': routerUrl.url=='/mapping/pns'}" routerLink="/mapping/pns"
                  *ngIf="getPermission('Mappings' , 'Dimensions', 'Pns')">PnS</a>
                <a mat-button href="#" [ngClass]="{'active': routerUrl.url=='/mapping/transactiontype'}"
                  routerLink="/mapping/transactiontype"
                  *ngIf="getPermission('Mappings' , 'Dimensions', 'TransactionType')">Transaction Type</a>
              </div>
            </li>
          </ul>
        </div>
      </li>


      <li class="submenu-level" [ngClass]="{'active-menu': isMenuHeaderActive('etl-configuration')}"
        *ngIf="getPermission('ETLConfiguration' , '', '')">
        <a mat-button>
          <span class="nav-icon">
            <img src="assets/icons/etl.svg" alt="ETL">
          </span>
          <mat-label>ETL Configuration</mat-label>
        </a>
        <div class="submenu submenu-border">
          <ul>
            <li class="has-submenu" [ngClass]="{'active': isSubMenuHeaderActive('configuration-masters')}"
              *ngIf="getPermission('ETLConfiguration' , 'ConfigurationMasters', '')">
              <a mat-button href="javascript:void(0)">Configuration masters</a>
              <div class="submenu-level-2">
                <a mat-button href="#" [ngClass]="{'active': routerUrl.url=='/master/source-system'}"
                  routerLink="/master/source-system"
                  *ngIf="getPermission('ETLConfiguration' , 'ConfigurationMasters', 'SourceSystem')">Source System</a>
                <a mat-button href="#" [ngClass]="{'active': routerUrl.url=='/master/integration-item'}"
                  routerLink="/master/integration-item"
                  *ngIf="getPermission('ETLConfiguration' , 'ConfigurationMasters', 'IntegrationItem')">Integration
                  Item</a>
                <a mat-button href="#" [ngClass]="{'active': routerUrl.url=='/master/interface-type'}"
                  routerLink="/master/interface-type"
                  *ngIf="getPermission('ETLConfiguration' , 'ConfigurationMasters', 'InterfaceType')">Interface Type</a>
                <a mat-button href="#" [ngClass]="{'active': routerUrl.url=='/master/source-data-category'}"
                  routerLink="/master/source-data-category"
                  *ngIf="getPermission('ETLConfiguration' , 'ConfigurationMasters', 'SourceDataCategory')">Source Data
                  Category</a>
                <a mat-button href="#" [ngClass]="{'active': routerUrl.url=='/master/inbound-table-name'}"
                  routerLink="/master/inbound-table-name"
                  *ngIf="getPermission('ETLConfiguration' , 'ConfigurationMasters', 'InboundTableName')">Inbound Table
                  Name</a>
                <a mat-button href="#" [ngClass]="{'active': routerUrl.url=='/master/outbound-table-name'}"
                  routerLink="/master/outbound-table-name"
                  *ngIf="getPermission('ETLConfiguration' , 'ConfigurationMasters', 'OutboundTableName')">Outbound Table
                  Name</a>
                <a mat-button href="#" [ngClass]="{'active': routerUrl.url=='/master/source-data-from'}"
                  routerLink="/master/source-data-from"
                  *ngIf="getPermission('ETLConfiguration' , 'ConfigurationMasters', 'SourceDataFrom')">Source Data
                  From</a>
                <a mat-button href="#" [ngClass]="{'active': routerUrl.url=='/master/validation-tier'}"
                  routerLink="/master/validation-tier"
                  *ngIf="getPermission('ETLConfiguration' , 'ConfigurationMasters', 'ValidationTier')">Validation
                  Tier</a>
                <a mat-button href="#" [ngClass]="{'active': routerUrl.url=='/master/dvf-type'}"
                  routerLink="/master/dvf-type"
                  *ngIf="getPermission('ETLConfiguration' , 'ConfigurationMasters', 'DVFType')">DVF Type</a>
                <a mat-button href="#" [ngClass]="{'active': routerUrl.url=='/master/validation-rule'}"
                  routerLink="/master/validation-rule"
                  *ngIf="getPermission('ETLConfiguration' , 'ConfigurationMasters', 'ValidationRule')">Validation
                  Rule</a>
                <a mat-button href="#" [ngClass]="{'active': routerUrl.url=='/master/dt-type'}"
                  routerLink="/master/dt-type"
                  *ngIf="getPermission('ETLConfiguration' , 'ConfigurationMasters', 'DTType')">DT Type</a>
                <a mat-button href="#" [ngClass]="{'active': routerUrl.url=='/master/transformation-rule'}"
                  routerLink="/master/transformation-rule"
                  *ngIf="getPermission('ETLConfiguration' , 'ConfigurationMasters', 'TransformationRule')">Transformation
                  Rule</a>
              </div>
            </li>
            <!-- <li class="has-submenu">
              <div class="sidemenu-dropdown"></div>
              <a mat-button href="javascript:void(0)">ADF Metadata Config</a>
              <div class="submenu-level-2">
                <a mat-button href="#">SFTP ADF Meta Data</a>
                <a mat-button href="#">D365 ADF Meta Data</a>
                <a mat-button href="#">Data warehouse ADF Meta Data</a>
              </div>
            </li> -->
            <li class="has-submenu" [ngClass]="{'active': isSubMenuHeaderActive('configuration-management')}"
              *ngIf="getPermission('ETLConfiguration' , 'ConfigurationManagement', '')">
              <div class="sidemenu-dropdown"></div>
              <a mat-button href="javascript:void(0)">Configuration management</a>
              <div class="submenu-level-2">
                <a mat-button href="#" [ngClass]="{'active': routerUrl.url=='/integration-setup'}"
                  routerLink="/integration-setup"
                  *ngIf="getPermission('ETLConfiguration' , 'ConfigurationManagement', 'IntegrationSetup')">Integration
                  Setup</a>
                <a mat-button href="#" [ngClass]="{'active': routerUrl.url=='/integration-review'}"
                  routerLink="/integration-review"
                  *ngIf="getPermission('ETLConfiguration' , 'ConfigurationManagement', 'IntegrationReview')">Integration
                  Review</a>
                <a mat-button href="#" [ngClass]="{'active': routerUrl.url=='/integration-approval'}"
                  routerLink="/integration-approval"
                  *ngIf="getPermission('ETLConfiguration' , 'ConfigurationManagement', 'IntegrationApproval')">Integration
                  Approval</a>
              </div>
            </li>
          </ul>
        </div>
      </li>

      <li *ngIf="getPermission('IntegrationMonitoring' , '', '')">
        <a mat-button class="menu-link" routerLink="/integration-monitoring">
          <span class="nav-icon">
            <img src="assets/icons/monitoring.svg" alt="Monitoring">
          </span>
          <mat-label>Integration Monitoring</mat-label>
        </a>
      </li>

      <li *ngIf="getPermission('HFM' , '', '')">
        <a mat-button class="menu-link" routerLink="/hfm-all-submission">
          <span class="nav-icon">
            <img src="assets/icons/monitoring.svg" alt="HFM Submission">
          </span>
          <mat-label>HFM Submission</mat-label>
        </a>
      </li>
      <!-- <li *ngIf="getPermission('HFM' , '', '')">
        <a mat-button class="menu-link" routerLink="/onestream-all-submission">
          <span class="nav-icon">
            <img src="assets/icons/monitoring.svg" alt="Onestream Submission">
          </span>
          <mat-label>Onestream Submission</mat-label>
        </a>
      </li> -->

      <li class="submenu-level" *ngIf="getPermission('UserAdmin' , '', '')">
        <a mat-button class="menu-link">
          <span class="nav-icon">
            <img src="assets/icons/user-admin.svg" alt="User admin">
          </span>
          <mat-label>User Admin</mat-label>
        </a>
        <div class="submenu submenu-border">
          <ul>
            <li class="has-submenu submenu-arrow" [ngClass]="{'active': routerUrl.url=='/role-master'}"
              *ngIf="getPermission('UserAdmin' , 'RolesAndPermissions', '')">
              <a mat-button routerLink="/role-master">Roles</a>
            </li>
            <li class="has-submenu submenu-arrow" [ngClass]="{'active': routerUrl.url=='/scope-master'}"
              *ngIf="getPermission('UserAdmin' , 'UserScopes', '')">
              <a mat-button routerLink="/scope-master">Scope</a>
            </li>
            <li class="has-submenu submenu-arrow" [ngClass]="{'active': routerUrl.url=='/user-master'}"
              *ngIf="getPermission('UserAdmin' , 'UserProfiles', '')">
              <a mat-button routerLink="/user-master">User Profiles</a>
            </li>
          </ul>
        </div>
      </li>

      <li *ngIf="getPermission('UploadFile' , '', '')">
        <a mat-button class="menu-link" routerLink="/upload-file">
          <span class="nav-icon">
            <img src="assets/icons/file-upload.svg" alt="Monitoring">
          </span>
          <mat-label>Upload File</mat-label>
        </a>
      </li>

      <li class="submenu-level" *ngIf="getPermission('BankingIntegration' , '', '')">
        <a mat-button>
          <span class="nav-icon">
            <img src="assets/icons/user-admin.svg" alt="Banking Integration">
          </span>
          <mat-label>Banking Integration</mat-label>
        </a>
        <div class="submenu submenu-border">
          <ul>
            <li class="has-submenu" [ngClass]="{'active': routerUrl.url=='/banking-integration/bank-statement'}"
              *ngIf="getPermission('BankingIntegration' , 'Master', '')">
              <div class="sidemenu-dropdown"></div>
              <a mat-button href="javascript:void(0)">Master</a>
              <div class="submenu-level-2">
                <a mat-button [ngClass]="{'active': routerUrl.url=='/banking-integration/bank-program'}"
                  routerLink="/banking-integration/bank-program"
                  *ngIf="getPermission('BankingIntegration' , 'Master', 'Programme')">Programme</a>
                <a mat-button [ngClass]="{'active': routerUrl.url=='/banking-integration/bank'}"
                  routerLink="//banking-integration/bank"
                  *ngIf="getPermission('BankingIntegration' , 'Master', 'Bank')">Bank</a>
                <a mat-button [ngClass]="{'active': routerUrl.url=='/banking-integration/legal-entity'}"
                  routerLink="/banking-integration/legal-entity"
                  *ngIf="getPermission('BankingIntegration' , 'Master', 'LegalEntity')">Legal Entity</a>
                <a mat-button [ngClass]="{'active': routerUrl.url=='/banking-integration/bank-operation-master'}"
                  routerLink="/banking-integration/bank-operation-master"
                  *ngIf="getPermission('BankingIntegration' , 'Master', 'BankOperation')">Bank Operation</a>
                <a mat-button [ngClass]="{'active': routerUrl.url=='/banking-integration/message-format-master'}"
                  routerLink="/banking-integration/message-format-master"
                  *ngIf="getPermission('BankingIntegration' , 'Master', 'MessageFormat')">Message Format</a>
                <a mat-button [ngClass]="{'active': routerUrl.url=='/banking-integration/connectivity-protocol-master'}"
                  routerLink="/banking-integration/connectivity-protocol-master"
                  *ngIf="getPermission('BankingIntegration' , 'Master', 'ConnectivityProtocol')">Connectivity Protocol</a>
                <a mat-button [ngClass]="{'active': routerUrl.url=='/banking-integration/bank-reason-code'}"
                  routerLink="/banking-integration/bank-reason-code"
                  *ngIf="getPermission('BankingIntegration' , 'Master', 'BankReasonCode')">Bank Reason Code</a>
                <a mat-button [ngClass]="{'active': routerUrl.url=='/banking-integration/bank-operation-status'}"
                  routerLink="/banking-integration/bank-operation-status"
                  *ngIf="getPermission('BankingIntegration' , 'Master', 'BankOperationStatus')">Bank Operation Status</a>
                <a mat-button [ngClass]="{'active': routerUrl.url=='/banking-integration/d365-status'}"
                  routerLink="/banking-integration/d365-status"
                  *ngIf="getPermission('BankingIntegration' , 'Master', 'D365Status')">D365 Status</a>
              </div>
            </li>
            <li class="has-submenu" [ngClass]="{'active': isSubMenuHeaderActive('dimension-mapping')}"
              *ngIf="getPermission('BankingIntegration' , 'Configuration', '')">
              <div class="sidemenu-dropdown"></div>
              <a mat-button href="javascript:void(0)">Configuration</a>
              <div class="submenu-level-2">
                <a mat-button [ngClass]="{'active': routerUrl.url=='/banking-integration/bank-details'}"
                  routerLink="/banking-integration/bank-details"
                  *ngIf="getPermission('BankingIntegration' , 'Configuration', 'BankMapping')">Bank Mapping</a>
                <a mat-button [ngClass]="{'active': routerUrl.url=='/banking-integration/configuration'}"
                  routerLink="/banking-integration/configuration"
                  *ngIf="getPermission('BankingIntegration' , 'Configuration', 'BankConnection')">Bank Connection</a>
              </div>
            </li>
            <li class="has-submenu" [ngClass]="{'active': isSubMenuHeaderActive('dimension-mapping')}"
              *ngIf="getPermission('BankingIntegration' , 'FinanceReport', '')">
              <div class="sidemenu-dropdown"></div>
              <a mat-button href="javascript:void(0)">Finance Report</a>
              <div class="submenu-level-2">
                <a mat-button href="#" [ngClass]="{'active': routerUrl.url=='/banking-integration/account-statements'}"
                  routerLink="/banking-integration/account-statements"
                  *ngIf="getPermission('BankingIntegration' , 'FinanceReport', 'AccountStatement')">Account Statement</a>
                <a mat-button href="#" [ngClass]="{'active': routerUrl.url=='/banking-integration/disbursements'}"
                  routerLink="/banking-integration/disbursements"
                  *ngIf="getPermission('BankingIntegration' , 'FinanceReport', 'Disbursement')">Disbursement</a>
                <!-- <a mat-button href="#" [ngClass]="{'active': routerUrl.url=='/banking-integration/collections'}"
                  routerLink="/banking-integration/collections"
                  *ngIf="getPermission('BankingIntegration' , 'FinanceReport', 'Collection')">Collection</a> -->
              </div>
            </li>
            <li class="has-submenu submenu-arrow"
              *ngIf="getPermission('BankingIntegration' , 'UploadBankStatement', '')"
              [ngClass]="{'active': routerUrl.url=='/banking-integration/bank-statement'}">
              <a mat-button routerLink="/banking-integration/bank-statement">Upload Bank Statement</a>
            </li>
          </ul>
        </div>
      </li>
    </ul>
  </nav>

</div>